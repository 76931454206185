import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ModalContext, ModalState } from '../../../state/context/ModalContext'
import { useKeyPress } from '../../../hooks/useKeyPress'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { CustomIcon, IconType } from '../CustomIcon'
import returnArrow from '../../../assets/svg/actions/return.svg'
import closeIcon from '../../../assets/svg/actions/close.svg'
import { CapitalizedText } from '../TextComponents'
import { useTranslation } from 'react-i18next'
import { useQueryParameters } from '../../../utils/router'
import { firstLogin } from '../../../state/storage'
import { FiltersContext } from '../../../state/context/FiltersContext'
import { MessageContext } from '../../../state/context/MessageContext'

/* USAGE:
 const { setModal } = useContext<ModalState>(ModalContext)

 const MyModalBody = () => {
 return (
 <div>
 <h1>Hello World</h1>
 <p>This is a modal</p>
 </div>
 )
 }

 setModal({
 header: 'My Modal Header',
 body: <MyModalBody />
 })
 */

export interface SpotterModal {
  index?: number
  header?: React.ReactNode
  returnAction?: () => void
  useOnClickOutside?: boolean
  body: React.ReactNode
}

export const Modal = () => {
  const { t } = useTranslation()
  const { modal, setModal } = useContext<ModalState>(ModalContext)
  const { message } = useContext(MessageContext)
  const { filtersOpen } = useContext(FiltersContext)
  const queryParams = useQueryParameters()
  const isFirst = Boolean(queryParams.get('first'))
  const closeOnClickOutside = modal?.useOnClickOutside ?? true

  useKeyPress(() => setModal(null), ['Escape'])

  const componentRef = useRef(null)
  useOnClickOutside(componentRef, () => closeOnClickOutside && !message && !firstLogin() && !isFirst && setModal(null))

  const iconStyles = 'w-5 h-5 bg-gray-200 hover:bg-gray-50'

  return modal ? (
    <ScreenWrapper zIndex={(modal.index ?? filtersOpen) ? 999 : 800} className={'backdrop-filter backdrop-blur-sm'}>
      <ModalContainer ref={componentRef}>
        {modal.header ? (
          <div className={`flex w-full justify-between items-center relative px-3 sm:px-10`}>
            {modal.returnAction && (
              <div className={'absolute left-10'}>
                <CustomIcon
                  iconType={IconType.VECTOR}
                  path={returnArrow}
                  alt={t('common.iconAlts.return')}
                  onClick={modal.returnAction}
                  styles={iconStyles}
                />
              </div>
            )}
            <CapitalizedText className={`w-full text-gray-50 text-center text-125 font-semibold`}>
              {modal.header}
            </CapitalizedText>
            {!firstLogin() && !isFirst && (
              <div className={'absolute right-10'}>
                <CustomIcon
                  iconType={IconType.VECTOR}
                  path={closeIcon}
                  alt={t('common.iconAlts.close')}
                  onClick={() => setModal(null)}
                  styles={iconStyles}
                />
              </div>
            )}
          </div>
        ) : (
          !firstLogin() &&
          !isFirst && (
            <div className={'absolute right-10'}>
              <CustomIcon
                iconType={IconType.VECTOR}
                path={closeIcon}
                alt={t('common.iconAlts.close')}
                onClick={() => setModal(null)}
                styles={iconStyles}
              />
            </div>
          )
        )}
        <div className={'px-3 sm:px-10'}>{modal.body}</div>
      </ModalContainer>
    </ScreenWrapper>
  ) : null
}

interface ScreenWrapperProps {
  zIndex: number
}

const ScreenWrapper = styled.div<ScreenWrapperProps>`
  ${tw`flex w-full h-screen justify-center items-center fixed inset-0 overflow-hidden bg-gray-700/60 transition-all ease-in-out duration-300`}
  z-index: ${({ zIndex }) => zIndex};
`

const ModalContainer = styled.div`
  ${tw`relative flex flex-col py-10 gap-7 bg-gray-600/70 border border-gray-500 rounded-lg shadow-lg max-h-screen md:min-w-125`}
`

export const ModalActions = styled.div`
  ${tw`flex gap-6 pt-8 w-full justify-center items-center`}
`

export const ModalText = styled(CapitalizedText)`
  ${tw`text-center text-gray-200`}
`

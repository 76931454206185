import React, { useContext, useEffect, useState } from 'react'
import { initI18n } from './utils/i18n'
import { useCancelToken } from './api/client'
import { UserInfoContext } from './state/context/UserInfoContext'
import { useHistory } from 'react-router-dom'
import { GlobalMessage } from './global-message/GlobalMessage'
import { getAuthInfo } from './api/auth'
import { FullScreenLoading } from './components/shared/Loading'
import Routes from './layout/routes/Routes'
import { useErrorHandling } from './hooks/handleError'
import { getIntegrationStates, getUserSettings } from './api/settings/profile'

initI18n('en')
const App = () => {
  const { createCancelToken } = useCancelToken()
  const { authInfo, setAuthInfo, setUserSettings, setIntegrationStates } = useContext(UserInfoContext)
  const handleError = useErrorHandling()
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getAuthInfo(cancelToken.token)
      .then(setAuthInfo)
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [setAuthInfo, history, createCancelToken])

  useEffect(() => {
    const cancelToken = createCancelToken()
    authInfo.isLoggedIn &&
      Promise.all([
        getUserSettings(cancelToken.token).then(setUserSettings),
        getIntegrationStates(cancelToken.token).then(setIntegrationStates)
      ]).catch(handleError)
  }, [authInfo, createCancelToken, handleError, setIntegrationStates, setUserSettings])

  if (loading) return <FullScreenLoading />

  return (
    <>
      <Routes />
      <GlobalMessage />
    </>
  )
}

export default App

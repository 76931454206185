import { TFunction, useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useQueryParameters } from '../../utils/router'
import { NavbarLink, NavbarLinkId } from '../navigation/Sidenav'
import { PrivateRoute } from './PrivateRoute'
import { SubLayout } from '../SubLayout'
import { UserList } from '../../components/admin/users/UserList'
import { UserDetails } from '../../components/admin/users/UserDetails'
import { AdminIntegrations, NewIntegration } from '../../components/admin/integrations/AdminIntegrations'
import { Vendors } from '../../utils/vendors'
import { AwsIntegrationDetails } from '../../components/admin/integrations/aws/AwsIntegrationDetails'
import { AzureIntegrationDetails } from '../../components/admin/integrations/azure/AzureIntegrationDetails'
import { GcpIntegrationDetails } from '../../components/admin/integrations/gcp/GcpIntegrationDetails'
import { TenantCostAnomalyRules } from '../../components/admin/alerts/TenantCostAnomalyRules'
import { TenantCostAnomalyRuleDetails } from '../../components/admin/alerts/TenantCostAnomalyRuleDetails'
import { NestedRoutesProps } from './Routes'
import { hasIntegrationErrors, TenantIntegrationStates } from '../../api/settings/profile'
import { PingType } from '../../components/shared/tabs/Tab'
import { UserRole } from '../../api/auth'
import { ADMIN_ROLES } from '../../components/admin/roleConstants'

export const AdminRoutes = ({ path }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authInfo, integrationStates } = useContext(UserInfoContext)
  const queryParams = useQueryParameters()
  const isFirst = Boolean(queryParams.get('first'))
  const hasIntegration = authInfo.awsIntegration || authInfo.azureIntegration || authInfo.gcpIntegration
  const isAdmin = authInfo.roles.some(role => role === UserRole.ADMIN)
  const links: NavbarLink[] = getAdminSubLinks('/admin', t, authInfo.roles, integrationStates)

  useEffect(() => {
    history.location.pathname.includes('users')
      ? history.push(`${path}/users`)
      : history.push(history.location.pathname)
  }, [authInfo.tenant.id])

  return (
    <>
      {history.location.pathname === path ? (
        <Redirect to={isFirst || !hasIntegration ? `${links[1].path}` : `${links[0].path}`} />
      ) : (
        history.location.pathname === `${path}/alerts` && isAdmin && <Redirect to={`${path}/alerts/costs`} />
      )}
      <PrivateRoute exact={true} path={`${path}/users`}>
        <SubLayout>
          <UserList />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/users/:userId`}>
        <SubLayout>
          <UserDetails />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/integrations`}>
        {authInfo.awsIntegration && authInfo.awsAccess ? (
          <Redirect to={`${path}/integrations/aws`} />
        ) : authInfo.azureIntegration && authInfo.azureAccess ? (
          <Redirect to={`${path}/integrations/azure`} />
        ) : authInfo.gcpIntegration && authInfo.gcpAccess ? (
          <Redirect to={`${path}/integrations/gcp`} />
        ) : (
          <>
            <Redirect to={`${path}/integrations?first=true`} />
            <SubLayout>
              <NewIntegration />
            </SubLayout>
          </>
        )}
      </PrivateRoute>
      {Vendors.map(vendor => (
        <PrivateRoute key={vendor} exact={true} path={`${path}/integrations/${vendor.toLowerCase()}`}>
          <SubLayout>
            <AdminIntegrations selectedVendor={vendor} />
          </SubLayout>
        </PrivateRoute>
      ))}
      <PrivateRoute exact={true} path={`${path}/integrations/aws/:masterAccountId`}>
        <SubLayout>
          <AwsIntegrationDetails />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/integrations/azure/:azureTenantId`}>
        <SubLayout>
          <AzureIntegrationDetails />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/integrations/gcp/:organizationId`}>
        <SubLayout>
          <GcpIntegrationDetails />
        </SubLayout>
      </PrivateRoute>
      {isAdmin && (
        <>
          <PrivateRoute exact={true} path={`${path}/alerts/costs`}>
            <SubLayout>
              <TenantCostAnomalyRules />
            </SubLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={`${path}/alerts/costs/:ruleId`}>
            <SubLayout>
              <TenantCostAnomalyRuleDetails />
            </SubLayout>
          </PrivateRoute>
        </>
      )}
      <PrivateRoute exact={true} path={`${path}/*`}>
        <SubLayout>{t('routeNotFound')}</SubLayout>
      </PrivateRoute>
    </>
  )
}

export const getAdminSubLinks = (
  path: string,
  t: TFunction,
  userRoles: UserRole[],
  integrationStates: TenantIntegrationStates
): NavbarLink[] => {
  return [
    {
      id: NavbarLinkId.ADMIN_USERS,
      label: t('admin.subnav.users'),
      path: `${path}/users`,
      display: userRoles.some(role => ADMIN_ROLES.includes(role))
    },
    {
      id: NavbarLinkId.ADMIN_INTEGRATIONS,
      label: t('admin.subnav.integrations'),
      path: `${path}/integrations`,
      display: userRoles.some(role => ADMIN_ROLES.includes(role)),
      ping:
        (hasIntegrationErrors(integrationStates.aws) ||
          hasIntegrationErrors(integrationStates.azure) ||
          hasIntegrationErrors(integrationStates.gcp)) &&
        PingType.ERROR
    },
    {
      id: NavbarLinkId.ADMIN_ALERTS,
      label: t('admin.subnav.alerts.mainPath'),
      path: `${path}/alerts`,
      display: userRoles.some(role => role === UserRole.ADMIN),
      links: [
        {
          id: NavbarLinkId.ADMIN_ALERTS,
          label: t('admin.subnav.alerts.costs'),
          path: `${path}/alerts/costs`,
          display: userRoles.some(role => role === UserRole.ADMIN)
        }
      ]
    }
  ]
}

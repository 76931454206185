import { Redirect, useHistory } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { SubLayout } from '../SubLayout'
import { Tenants } from '../../components/master-admin/Tenants'
import { SystemAdminList } from '../../components/master-admin/SystemAdminList'
import { SystemAdminDetails } from '../../components/master-admin/SystemAdminDetails'
import { MasterAdminUsersList } from '../../components/master-admin/MasterAdminUsersList'
import { MasterAdminUserDetails } from '../../components/master-admin/MasterAdminUserDetails'
import React, { useContext } from 'react'
import { NestedRoutesProps } from './Routes'
import { NavbarLink, NavbarLinkId } from '../navigation/Sidenav'
import { TFunction, useTranslation } from 'react-i18next'
import { featuresFlags } from '../../state/featuresFlags'
import { AuthInfo, UserRole } from '../../api/auth'
import { UserInfoContext } from '../../state/context/UserInfoContext'

export const MasterAdminRoutes = ({ path }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const history = useHistory()
  const links: NavbarLink[] = getMasterAdminSubLinks(path, authInfo, t)
  const tenantsPath = links.find(link => link.id === NavbarLinkId.MASTER_ADMIN_TENANTS)?.path
  const systemAdminsPath = links.find(link => link.id === NavbarLinkId.MASTER_ADMIN_SYSTEM_ADMINS)?.path
  const usersPath = links.find(link => link.id === NavbarLinkId.MASTER_ADMIN_USERS)?.path

  return (
    <>
      {history.location.pathname === `${path}` && <Redirect to={links[0].path} />}
      <PrivateRoute exact={true} path={tenantsPath || path}>
        <SubLayout>
          <Tenants />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={systemAdminsPath || path}>
        <SubLayout>
          <SystemAdminList />
        </SubLayout>
      </PrivateRoute>
      {systemAdminsPath && (
        <PrivateRoute exact={true} path={`${systemAdminsPath}/:userId`}>
          <SubLayout>
            <SystemAdminDetails />
          </SubLayout>
        </PrivateRoute>
      )}
      <PrivateRoute exact={true} path={usersPath || path}>
        <SubLayout>
          <MasterAdminUsersList />
        </SubLayout>
      </PrivateRoute>
      {usersPath && (
        <PrivateRoute exact={true} path={`${usersPath}/:userId`}>
          <SubLayout>
            <MasterAdminUserDetails />
          </SubLayout>
        </PrivateRoute>
      )}
      <PrivateRoute exact={true} path={`${path}/*`}>
        <SubLayout>{t('routeNotFound')}</SubLayout>
      </PrivateRoute>
    </>
  )
}

export const getMasterAdminSubLinks = (path: string, authInfo: AuthInfo, t: TFunction): NavbarLink[] => {
  return [
    {
      id: NavbarLinkId.MASTER_ADMIN_TENANTS,
      label: t('subNav.masterAdmin.tenants'),
      path: `${path}/tenants`,
      display: featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN)
    },
    {
      id: NavbarLinkId.MASTER_ADMIN_SYSTEM_ADMINS,
      label: t('subNav.masterAdmin.systemAdmins'),
      path: `${path}/system-admins`,
      display: featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN)
    },
    {
      id: NavbarLinkId.MASTER_ADMIN_USERS,
      label: t('subNav.masterAdmin.users'),
      path: `${path}/users`,
      display: featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN)
    }
  ]
}

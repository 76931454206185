import { TFunction, useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { Redirect, useHistory } from 'react-router-dom'
import { NavbarLink, NavbarLinkId } from '../navigation/Sidenav'
import { PrivateRoute } from './PrivateRoute'
import { SubLayout } from '../SubLayout'
import { UserProfile } from '../../components/settings/profile/UserProfile'
import { featuresFlags } from '../../state/featuresFlags'
import { NotificationSettings } from '../../components/settings/notifications/NotificationSettings'
import { Vendor } from '../../utils/vendors'
import { NestedRoutesProps } from './Routes'
import { AuthInfo } from '../../api/auth'

export const SettingsRoutes = ({ path }: NestedRoutesProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const history = useHistory()
  const links: NavbarLink[] = getSettingsSubLinks('/settings', authInfo, t)

  return (
    <>
      {history.location.pathname === `${path}` && <Redirect to={`${links[0].path}`} />}
      <PrivateRoute exact={true} path={`${path}/profile`}>
        <SubLayout>
          <UserProfile />
        </SubLayout>
      </PrivateRoute>
      {featuresFlags.notifications &&
        authInfo.notificationsAccess &&
        history.location.pathname === `${path}/notifications` &&
        (authInfo.awsAccess && authInfo.awsIntegration ? (
          <Redirect to={`${path}/notifications/aws`} />
        ) : authInfo.azureAccess && authInfo.azureIntegration ? (
          <Redirect to={`${path}/notifications/azure`} />
        ) : authInfo.gcpAccess && authInfo.gcpIntegration ? (
          <Redirect to={`${path}/notifications/gcp`} />
        ) : (
          <Redirect to={`${path}/notifications/na`} />
        ))}
      <PrivateRoute exact={true} path={`${path}/notifications/aws`}>
        <SubLayout>
          <NotificationSettings selectedVendor={Vendor.AWS} />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/notifications/azure`}>
        <SubLayout>
          <NotificationSettings selectedVendor={Vendor.AZURE} />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/notifications/gcp`}>
        <SubLayout>
          <NotificationSettings selectedVendor={Vendor.GCP} />
        </SubLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={`${path}/*`}>
        <SubLayout>{t('routeNotFound')}</SubLayout>
      </PrivateRoute>
    </>
  )
}

export const getSettingsSubLinks = (path: string, authInfo: AuthInfo, t: TFunction): NavbarLink[] => {
  return [
    {
      id: NavbarLinkId.SETTINGS_PROFILE,
      label: t('subNav.settings.profile'),
      path: `${path}/profile`,
      display: true
    },
    {
      id: NavbarLinkId.SETTINGS_NOTIFICATIONS,
      label: t('subNav.settings.notifications'),
      path: `${path}/notifications`,
      display: featuresFlags.notifications && authInfo.notificationsAccess
    }
  ]
}
